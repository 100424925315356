import React from 'react'

import { useIntl } from 'intl'
import { useUser } from 'modules/user'

import { Avatar } from 'components/dataDisplay'
import { HoverablePopover } from 'components/navigation'
import { navMessages } from 'compositions/navigation'
import { useUserNav } from '../../util'

import Section from './components/Section/Section'


const UserNav: React.FunctionComponent = () => {
  const sections = useUserNav()

  const { user } = useUser()
  const firstName = user?.firstName
  const lastName = user?.lastName

  const intl = useIntl()
  const buttonAriaLabel = intl.formatMessage(navMessages.aria.profileButton)

  return (
    <HoverablePopover className="align-center relative flex h-full justify-center">
      {
        ({ open, close }) => (
          <>
            <HoverablePopover.Button data-testid="profileMenuBtn" aria-label={buttonAriaLabel}>
              <Avatar
                firstName={firstName}
                lastName={lastName}
                size={40}
                isActive={open}
                data-testid="userAvatar"
              />
            </HoverablePopover.Button>
            <HoverablePopover.Panel as="nav" className="desktop:top-full desktop:w-[368rem] absolute right-0 bg-black pr-40 pt-32 text-right" aria-label="Profile">
              {
                sections.map((data) => (
                  <Section key={data.id} data={data} closeNav={close} />
                ))
              }
            </HoverablePopover.Panel>
          </>
        )
      }
    </HoverablePopover>
  )
}

export default React.memo(UserNav)
