import React, { useEffect } from 'react'
import cx from 'classnames'
import { track } from 'analytics'
import { useIntl } from 'intl'
import { sanitizeForAnalytics } from 'helpers'

import { WidthContainer } from 'components/layout'
import type { TextStyle, TextColor } from 'components/dataDisplay'
import { HoverablePopover } from 'components/navigation'
import { NavItem } from 'compositions/navigation'

import s from './NavRootItem.module.css'


const backgroundToClassName = {
  'faded-pink': 'bg-faded-pink',
  'dark-pink': 'bg-dark-pink',
  'gold-50': 'bg-gold-50',
  'vinous': 'bg-vinous',
} as const

type Background = keyof typeof backgroundToClassName

type NavItemProps = {
  data: Navigation.HeaderDesktopSectionItem
  className?: string
  buttonClassName?: string
  popoverPanelClassName?: string
  style?: TextStyle
  color?: TextColor
  background?: Background
  hoverColor?: 'gold-30'
  groupOpenedPopoverCountRef?: React.MutableRefObject<number>
  onClick?: () => void
  'data-testid'?: string
} & AllOrNothing<{
  activeColor?: TextColor
  isActive?: boolean
}>

const NavRootItem: React.FunctionComponent<NavItemProps> = (props) => {
  const { className, buttonClassName, data, style, color, background, hoverColor, activeColor, isActive,
    popoverPanelClassName, groupOpenedPopoverCountRef, 'data-testid': dataTestId } = props

  const { id, title: sectionTitle, list, subNavLinksPosition, withColumns, bannerComponent, onDisplay } = data

  const intl = useIntl()

  const formattedTitle = intl.formatMessage(sectionTitle)

  const buttonClassNames = cx(buttonClassName, 'flex cursor-pointer items-center', backgroundToClassName[background], {
    [`text-${style}`]: style,
    [`hover:text-${hoverColor}`]: hoverColor,
    [`text-${color}`]: color && !isActive,
    [`text-${activeColor}`]: activeColor && isActive,
  })

  const isColumnFlowEnabled = withColumns && list.length > 5
  /*
   rearrange items to split them into 2 columns like:
   0 3
   1 4
   2 5
   will be represented as [ 0, 3, 1, 4, 2, 5 ] array
   it is required to split by items count, not items content to provide more control
   */
  const itemsByColumns = isColumnFlowEnabled ? list.map((_, index) => {
    const halfLength = Math.ceil(list.length / 2)
    const halfIndex = Math.floor(index / 2)
    return list[index % 2 * halfLength + halfIndex]
  }) : list

  const isMenuOnRightSide = subNavLinksPosition === 'right'

  return (
    <HoverablePopover className={cx('flex items-stretch', className)} groupOpenedPopoverCountRef={groupOpenedPopoverCountRef}>
      {
        ({ open, close }) => (
          <>
            <HoverablePopover.Button
              className={cx(buttonClassNames, {
                [s.activeButton]: open,
                [`text-${activeColor}`]: open && activeColor,
              })}
              data-testid={dataTestId}
            >
              {formattedTitle}
            </HoverablePopover.Button>
            <HoverablePopover.Overlay withDefaultStyle />
            <HoverablePopover.Panel className={cx(popoverPanelClassName, 'absolute left-0 top-full z-10 w-full bg-white py-24 text-black')}>
              <WidthContainer className={cx('flex items-start', isMenuOnRightSide && 'flex-row-reverse')}>
                {
                  Boolean(onDisplay) && (
                    <DisplayTracker onDisplay={onDisplay} />
                  )
                }
                <div className="flex-1 px-24">
                  <div role="list" className={cx(isMenuOnRightSide ? 'mx-auto w-[400rem]' : 'ml-auto w-[384rem]', isColumnFlowEnabled && 'grid grid-cols-2 gap-x-16')}>
                    {
                      itemsByColumns.map(({ id, title, to, href, toTab, label, content, exclusiveLabel, highlight, ref }) => (
                        <div key={id} role="listitem">
                          <NavItem
                            className={cx('inline-block py-10', highlight && 'font-bold')}
                            ref={ref}
                            title={title}
                            to={to}
                            href={href}
                            toTab={toTab}
                            label={label}
                            content={content}
                            exclusiveLabel={exclusiveLabel}
                            style="p2"
                            color={highlight ? 'gold-70' : 'black'}
                            hoverColor="gold-30"
                            activeColor="gold-30"
                            onClick={() => {
                              track('Desktop navigation menu click', {
                                blockName: sanitizeForAnalytics(typeof sectionTitle === 'string' ? sectionTitle : sectionTitle.en),
                                title: sanitizeForAnalytics(typeof title === 'string' ? title : title.en),
                                link: to || toTab || href,
                              })
                              close()
                            }}
                            data-testid={id}
                          />
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className="w-[560rem] flex-none">
                  {
                    bannerComponent ? React.createElement(bannerComponent, { placement: id, onClick: close }) : null
                  }
                </div>
              </WidthContainer>
            </HoverablePopover.Panel>
          </>
        )
      }
    </HoverablePopover>
  )
}

const DisplayTracker: React.FC<{ onDisplay: () => void }> = ({ onDisplay }) => {
  useEffect(() => {
    onDisplay()
  }, [ onDisplay ])

  return null
}


export default React.memo(NavRootItem)
