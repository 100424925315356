import React, { useCallback } from 'react'
import { track } from 'analytics'
import { openModal } from 'modals'

import { useResubscribeOffer } from 'modules/subscription'

import { Button, buttonMessages, type ButtonProps } from 'components/inputs'

import type { ResubscribeModalProps } from 'compositions/modals/ResubscribeModal/ResubscribeModal'


type ResubscribeButtonProps = Partial<
  Pick<
    ButtonProps,
    'className' | 'size' | 'style' | 'width' | 'fullWidth' | 'fullWidthOnMobile'
    | 'data-testid' | 'data-cnstrc-item-price' | 'data-cnstrc-item-variation-id' | 'loading'
  >
> & {
  title?: Intl.Message
  productToAdd?: ResubscribeModalProps['productToAdd']
  placement: string
  onProductAdded?: () => void
}

const ResubscribeButton: React.FunctionComponent<ResubscribeButtonProps> = (props) => {
  const {
    className,
    size = 56,
    style = 'primary',
    width,
    fullWidth,
    fullWidthOnMobile,
    productToAdd,
    title,
    loading,
    placement,
    onProductAdded,
    'data-testid': dataTestId = 'resubscribeButton',
    'data-cnstrc-item-price': dataCnstrcItemPrice,
    'data-cnstrc-item-variation-id': dataCnstrcItemVariationId,
  } = props

  const { eventOffer } = useResubscribeOffer()

  const handleClick = useCallback(() => {
    track('Banner click', {
      placement,
      content: 'Resubscribe',
      offer: eventOffer,
    })
    openModal('resubscribeModal', { productToAdd, onProductAdded })
  }, [ placement, eventOffer, productToAdd, onProductAdded ])

  return (
    <Button
      className={className}
      title={title || buttonMessages.resubscribeNow}
      size={size}
      style={style}
      width={width}
      fullWidth={fullWidth}
      fullWidthOnMobile={fullWidthOnMobile}
      data-testid={dataTestId}
      onClick={handleClick}
      loading={loading}
      // TODO: Improve the flow by making sure that this property is used only within the product description — added on 12–02–2024 by algeas
      data-cnstrc-btn="resubscribe"
      data-cnstrc-item-price={dataCnstrcItemPrice}
      data-cnstrc-item-variation-id={dataCnstrcItemVariationId}
      html
    />
  )
}


export default React.memo(ResubscribeButton)
