import React, { useRef } from 'react'
import { Popover } from '@headlessui/react'
import { usePathname, useSearchParams } from 'router'
import { sanitizeForAnalytics } from 'helpers'

import { track } from 'analytics'
import { NavList, NavItem } from 'compositions/navigation'
import { useDesktopNav } from '../../util'

import NavRootItem from '../NavRootItem/NavRootItem'


const Nav: React.FunctionComponent = () => {
  const pathname = usePathname()
  const [ searchParams ] = useSearchParams()

  const items = useDesktopNav()
  const groupOpenedPopoverCountRef = useRef<number>(0)

  const activeTabLink = 'tab' in searchParams ? `${pathname}?tab=${searchParams.tab}` : pathname

  return (
    <Popover.Group as={NavList} className="ml-32 flex flex-1 items-stretch self-stretch" listClassName="flex items-stretch" aria-label="Main">
      {
        items.map((item) => {
          const { id, title, to, list, content, withDivider } = item

          const dividerNode = Boolean(withDivider) && (
            <div className="border-solid-gray-80 desktop:h-32 mx-12" role="presentation" />
          )

          const isActive = to === activeTabLink || list?.some(({ to }) => (
            // this check required because we have different approach to pages with tabs:
            // - same pathname with tabs in perfumes/colognes subNav
            // - only pathname without tabs (but page has tabs), e.g. discover/brands
            to?.includes('?tab') ? to === activeTabLink : to === pathname
          ))

          if (list) {
            return (
              <li key={id} className="flex items-center justify-center">
                {dividerNode}
                <NavRootItem
                  className="self-stretch"
                  buttonClassName="px-12"
                  style="p2"
                  color="white"
                  data={item}
                  background={item.background}
                  groupOpenedPopoverCountRef={groupOpenedPopoverCountRef}
                  data-testid={id}
                />
              </li>
            )
          }

          return (
            <li key={id} className="flex items-center justify-center">
              {dividerNode}
              <NavItem
                className="mx-12 flex items-center self-stretch"
                style="p2"
                color="white"
                content={content}
                hoverColor="gold-30"
                activeColor="gold-30"
                to={to}
                title={title}
                isActive={isActive}
                onClick={() => {
                  track('Desktop navigation menu click', {
                    blockName: sanitizeForAnalytics(typeof title === 'string' ? title : title.en),
                    title: sanitizeForAnalytics(typeof title === 'string' ? title : title.en),
                    link: to,
                  })
                }}
                data-testid={id}
              />
            </li>
          )
        })
      }
    </Popover.Group>
  )
}


export default Nav
