import React from 'react'
import { track } from 'analytics'
import { usePathname } from 'router'
import { useUniqueId } from 'hooks'

import { Text } from 'components/dataDisplay'
import { NavItem } from 'compositions/navigation'


type SectionProps = {
  data: Navigation.HeaderProfileSection
  closeNav: () => void
}

const Section: React.FunctionComponent<SectionProps> = ({ data, closeNav }) => {
  const pathname = usePathname()

  const { title: sectionTitle, list } = data
  const textId = useUniqueId('user-section')

  return (
    <>
      <Text id={textId} message={sectionTitle} style="h8" color="gold-30" />
      <ul className="mb-24 mt-8 text-white" aria-labelledby={textId}>
        {
          list.map((item) => {
            if (item.list) {
              return null
            }

            const { id, title, label, to, toTab, content, onClick } = item

            const handleClick = (event: React.MouseEvent) => {
              closeNav()

              track('Desktop profile menu click', {
                link: to,
                title: typeof title === 'string' ? title : title.en,
                blockName: typeof sectionTitle === 'string' ? sectionTitle : sectionTitle.en,
              })

              if (typeof onClick === 'function') {
                onClick(event)
              }
            }

            return (
              <li key={id}>
                <NavItem
                  className="block py-12"
                  title={title}
                  label={label}
                  to={to}
                  toTab={toTab}
                  content={content}
                  style="p2"
                  hoverColor="gold-30"
                  activeColor="gold-30"
                  labelColor="gold-30"
                  isActive={pathname === to}
                  data-testid={id}
                  onClick={handleClick}
                />
              </li>
            )
          })
        }
      </ul>
    </>
  )
}


export default React.memo(Section)
