import React from 'react'
import { twcx } from 'helpers'

import { track } from 'analytics'
import { ButtonBase } from 'components/inputs'
import { Icon } from 'components/dataDisplay'

import s from './HeaderBannerContainer.module.css'


export type HeaderBannerContainerProps = {
  className?: string
  to?: string
  height: 48 | 56 | 64
  loading?: boolean
  trackParams?: Analytics.Events['Banner click']
  onClick?: () => void
  'data-testid'?: string
}

const heightClassMap = {
  48: 'h-48',
  56: 'h-56',
  64: 'h-64',
}

const HeaderBannerContainer: React.CFC<HeaderBannerContainerProps> = (props) => {
  const { children, className, to, height, loading, trackParams, onClick, 'data-testid': dataTestId } = props

  const rootClassName = twcx(
    s.root,
    'flex items-center justify-center text-white',
    heightClassMap[height],
    className
  )

  const content = loading ? <Icon name="32/loader" /> : children

  if (to || onClick) {
    return (
      <ButtonBase
        className={twcx(rootClassName, 'w-full')}
        to={to}
        loading={loading}
        onClick={() => {
          track('Banner click', trackParams)
          if (typeof onClick === 'function') {
            onClick()
          }
        }}
        data-testid={dataTestId}
      >
        {content}
      </ButtonBase>
    )
  }

  return (
    <div className={rootClassName} data-testid={dataTestId} role="complementary">
      {content}
    </div>
  )
}


export default HeaderBannerContainer
